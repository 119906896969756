<template>
    <BT-Blade-Items
        addBladeName="client-template"
        bladeName="client-templates"
        :bladesData="bladesData"
        canAdd
        canDelete
        clearBlades
        :headers="[
            { text: 'Template Name', value: 'templateName', title: 1, searchable: true }]"
        navigation="client-templates"
        useServerPagination
        title="Client Templates" />
</template>

<script>
export default {
    name: 'Client-Templates-Blade',
    props: {
        bladesData: null
    }
}
</script>